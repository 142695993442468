import * as React from 'react';
import {
  getMainScrollingContainer,
  // scrollContainerToTop,
} from 'lib/scroll-utils';
import { STORY_THUMB_ID } from 'components/story-detail/story-detail-card';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { createLogger } from '@common/log';
// import { StoryNavState } from './story-detail-screen/story-nav-state';
import {
  // getElementById,
  getElementsCombinedHeight,
} from 'components/dom-utils';
import { elementIds } from 'components/dom-utils/element-ids';

const log = createLogger('learn/scrolling-fx');

// function getCSSVar(
//   variableName: string,
//   elementId?: string
// ): string | undefined {
//   const element = elementId
//     ? document.getElementById(elementId)
//     : document.documentElement;

//   if (element) {
//     const styles = window.getComputedStyle(element);
//     const value = styles.getPropertyValue(variableName).trim();
//     return value;
//   }

//   return undefined;
// }

// function getPropertyValue(
//   propertyName: string,
//   element: string | HTMLElement = document.documentElement
// ): string | undefined {
//   const elementToUse =
//     typeof element === 'string' ? document.getElementById(element) : element;
//   if (elementToUse) {
//     const styles = window.getComputedStyle(elementToUse);
//     const value = styles.getPropertyValue(propertyName).trim();
//     return value;
//   }

//   return undefined;
// }

// function getPixelValue(value: string): number | undefined {
//   if (value && value.endsWith('px')) {
//     const parsedValue = parseFloat(value);
//     if (!isNaN(parsedValue)) {
//       return parsedValue;
//     }
//   }

//   return undefined;
// }

// (window as any).scroller = getMainScrollingContainer;

function scrollToChapterElement(
  currentChapter: HTMLDivElement,
  smooth = false
) {
  const scrollingContainer = getMainScrollingContainer();
  if (!scrollingContainer) {
    log.warn(`scrollToCurrentChapter - scrollingContainer not found, ignoring`);
    return;
  }

  if (!currentChapter) {
    // @frankharrison in the absense of a tabbar, what should be this behavior?
    // scrollToTabBar();
    return false;
    // scrollingContainer.scrollTo({ top: 0, behavior: 'auto' });
  }

  const currentChapterTop = currentChapter?.offsetTop;

  // combined height in pixels of the top sticky elements
  const topCompensation =
    getElementsCombinedHeight([
      // elementIds.STORY_HEADER_CONTAINER,
      // elementIds.CHAPTER_LIST_HEADER,
      elementIds.STORY_DETAIL_COVER,
      elementIds.STORY_ASSIGNMENT_BAR,
    ]) -
    getElementsCombinedHeight([
      elementIds.STORY_HEADER_CONTAINER,
      elementIds.CHAPTER_LIST_TOOLBAR,
    ]);

  const scrollToTop = currentChapterTop + topCompensation;

  // console.log('SCTP==>', scrollToTop);

  scrollingContainer.scrollTo({
    top: scrollToTop,
    left: 0,
    behavior: smooth ? 'smooth' : ('instant' as any), /// typescript is wrong here. 'instant' is a valid value
  });

  return true;
}

export function scrollToProgressPanel() {
  const progressPanel: HTMLDivElement = document.getElementById(
    elementIds.PROGRESS_PANEL
  ) as HTMLDivElement;

  // funny, it works with non-chapoter elements too
  return scrollToChapterElement(progressPanel, true);
}

export function scrollToCurrentChapter(smooth = false) {
  const currentChapter: HTMLDivElement = document.getElementById(
    elementIds.CURRENT_CHAPTER_ID
  ) as HTMLDivElement;
  return scrollToChapterElement(currentChapter, smooth);
}

// (window as any).scur = scrollToCurrentChapter;
// (window as any).scnxt = scrollToNextChapter;

export function useStoryThumbScrollingFx() {
  React.useLayoutEffect(() => {
    const scrollingContainer = getMainScrollingContainer();

    const intersectionObserver = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.intersectionRatio === 1) {
          storyDetailUiModel.setSolidHeader(false);
        } else {
          storyDetailUiModel.setSolidHeader(true);
        }
        if (entry.intersectionRatio === 0) {
          storyDetailUiModel.setShowTitleInHeader(true);
        } else {
          storyDetailUiModel.setShowTitleInHeader(false);
        }
      },
      {
        root: scrollingContainer,
        threshold: [0, 1],
      }
    );

    const thumbElement = document.getElementById(STORY_THUMB_ID);
    if (!thumbElement) {
      log.error('missing thumbElement');
      return;
    }

    intersectionObserver.observe(thumbElement);

    return () => {
      intersectionObserver.unobserve(thumbElement);
      intersectionObserver.disconnect();
    };
  }, []);
}

// function getTabBarTopPosition() {
//   const tabBar = getElementById(elementIds.STORY_TAB_BAR);

//   if (!tabBar) {
//     return 0;
//   }

//   const topCompensation = getElementsCombinedHeight([
//     elementIds.STORY_HEADER_CONTAINER,
//     elementIds.STORY_ASSIGNMENT_BAR,
//   ]);

//   const tabBarTop = tabBar?.offsetTop - topCompensation;
//   return tabBarTop;
// }

// export function scrollToTabBar(soft = false) {
//   const scrollingContainer = getMainScrollingContainer();
//   if (!scrollingContainer) {
//     log.warn(`scrollToCurrentChapter - scrollingContainer not found, ignoring`);
//     // scrollContainerToTop();
//     return false;
//   }

//   const currentScrollPos = scrollingContainer.scrollTop;

//   /// here's the weirdest thing: if we don't scroll to top first,
//   /// the tabbar scroll position is wrong. I don't know exactly why, but it
//   /// must have something to do with the fact that the tabbar is sticky.
//   /// So the fix is to scroll to top first, then scroll to the tabbar.
//   scrollContainerToTop();

//   const tabBarTop = getTabBarTopPosition();

//   const scrollTo = soft ? Math.min(tabBarTop, currentScrollPos) : tabBarTop;

//   scrollingContainer.scrollTo({
//     top: scrollTo,
//     left: 0,
//     behavior: 'instant' as any, /// typescript is wrong here. 'instant' is a valid value
//   });

//   return true;
// }
